import $ from 'jquery';

$('body').append('<div id="snippets-modal" class="modal fade bd-example-modal-lg" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true"><div class="modal-dialog modal-dialog-centered"><div class="modal-content"><div class="card-body border-2 p-2 overflow-auto" style="max-height: 80vh"><ul class="list-group"></ul></div></div></div></div>');
const modalElm = $('#snippets-modal');
const modal = new bootstrap.Modal(modalElm);

function openSnippetsModal(targetEl, category) {
    const url = new URL(window.location.origin + '/admin/snippets/list');
    url.searchParams.append('category', category);

    fetch(url, {headers: {'X-Requested-With': 'XMLHttpRequest'}})
        .then((response) => {
            if (!response.ok) {
                response.json().then((data) => alert(data.error));
                return "";
            }

            return response.json();
        })
        .then((response) => addSnippetContent(targetEl, response))
}

function addSnippetContent(targetEl, response) {
    const html = response.map(function (snippet) {
        let content = snippet;
        if (content.length > 150) {
            content = content.slice(0, 150);
            content += '...'
        }


        return '<li class="list-group-item cursor-pointer" data-content="' + snippet + '">' + content + '</li>';
    }).join('');

    showSnippets(targetEl, html)
}

function showSnippets(targetEl, html) {
    modalElm.find('.modal-content ul')
        .html(html)
        .find('li')
        .click((evt) => {
            targetEl.val(evt.target.dataset.content)
                .get(0).dispatchEvent(new Event('input'));

            toggleModal();
        });

    toggleModal();
}

const toggleModal = () => {
    modal.toggle('show');
}

$('[data-bs-snippets]').click(function () {
    const targetEl = $(this.attributes['data-bs-target'].value);
    const category = this.attributes['data-bs-snippets'].value;

    openSnippetsModal(targetEl, category);
});
